.powerbi-container, .report, .powerbi-container iframe {
    height: calc(100vh - 40px);
    width: 100%;
    border: 0;
}

.powerbi-placeholer {
    width: 100%;
    height: calc(100vh - 40px);
    text-align: center;
}

.with-left-menu .powerbi-placeholer {
    height: calc(100vh);
}

.powerbi-placeholer .loader-for-powerbi-placeholer {
    min-height: 100px  !important;
    width: 100px  !important;
    height: 100px  !important;
    display: block !important;
    position: absolute !important;
    top: 0 !important;;
    bottom: 0 !important;;
    left: 0 !important;;
    right: 0 !important;;
    margin: auto !important;;
}