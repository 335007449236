.value-container {
    cursor: pointer;
}

.field-input-for-report a {
    cursor: pointer;
}

.field-input-for-report {
    padding: 10px;
    border: 1px dashed #767676;
    border-radius: 5px;
}
.field-input-for-report .placeholder {
    color: #767676;
    cursor: pointer;
}

.field-input-for-report .dropdown {
    position: absolute;
    right: -18px;
}

.field-input-for-report .dropdown.distinct{
    right: 0px;
}

.field-input-for-report .dropdown .item {
    min-width: 160px;
}

.field-input-for-report .dropdown .item img {
    padding-top: 5px;
}

.field-input-for-report .dropdown .item i {
    position: absolute;
    right: 0;
}

.field-input-for-report .dropdown.distinct>i:nth-child(2) {
    position: absolute;
    top: -1px;
    margin: 0;
}