.folder-viewer-container {
    border-radius: .28571429rem;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15), 0 0 0 1px rgba(34, 36, 38, .15);
    >.item {
        margin: 0;
        padding: .75em 1em;
        color: rgba(0, 0, 0, .5);
        font-weight: 700;
        border-top: 1px solid rgba(34, 36, 38, .15);
        -webkit-transition: background .1s ease, color .1s ease;
        transition: background .1s ease, color .1s ease;
        cursor: pointer;
        position: relative;
        >.inner-add, >.delete-folder {
            opacity: 0;
        }

        &:hover>.inner-add, &:hover>.delete-folder {
            opacity: 1;
        }
    }
    >.inner-folders {
        padding-left: 25px;
        >.folder-viewer-container {
            box-shadow: none;
            >.item {
                border-top: none;
            }
        }
    }
    >.item:hover, >.item.current {
        background: 0 0;
        color: rgba(0, 0, 0, .87);
    }
}