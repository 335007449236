.introduction-page-container {
    width: 1028px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    /* width: 509px; */
    height: 531px;
    right: 0;
}

.introduction-page-container .introduction-text-container {
    font-size: 20px;
    line-height: 22px;
    margin-top: 50px;
}

.introduction-page-container .introduction-img-container {
    padding: 100px !important;
}

.introduction-page-container .introduction-img-container img{
    max-height: 400px;
}

.introduction-page-container .introduction-controls-container {
    position: fixed;
    bottom: 35px;
    width: 100%;
    left: 0;
    text-align: center;
}

.introduction-page-container .introduction-controls-container .introduction-controls-navigation {
    margin-bottom: 25px;
}

.introduction-controls-navigation i{
    cursor: pointer;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .introduction-page-container {
        width: 100% !important;
        height: auto !important;
    }

    .introduction-page-container>.grid{
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-bottom: 60px;
        padding-top: 25px;
    }

    .introduction-page-container>.grid>.row {
        display: block;
    }
    .introduction-page-container>.ui.grid>.row>.column {
        display: block;
        width: 100% !important;
    }
    .introduction-page-container .introduction-controls-container {
        bottom: 0px !important;
        background-color: white;
        border-top: 1px solid #e0e1e2;
        padding-top: 10px;
    }
    
    .introduction-page-container .introduction-controls-container .introduction-controls-navigation {
        display: inline-block;
        margin-right: 45px;
        margin-bottom: 10px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {  }

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}
