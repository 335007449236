.knowledgeBase-page-container .page-view {
    .header { 
        position: relative;
        .updater-info {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 35px;
            min-width: 127px;
            text-align: right;
            .user {
                padding-left: 35px;
                position: relative;
                cursor: default;

                .avatar {
                    position: absolute;
                    left: 0px;
                    top:0px;
                    width: 28px;
                    height: 28px;
                    background-size: cover;
                    border-radius: 500px;
                }
            }

            .update-at {
                color: rgba(0, 0, 0, .4);
                font-size: xx-small;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .history-btn {
            margin-left: 25px;
            cursor: pointer;
        }

        .path-explorer {
            position: absolute;
            bottom: -14px;
            left: 1px;
        }
    }

    .navigation-on-siblings-page-container a {
        cursor: pointer;
    }

    .subscribers-container {
        position: absolute;
        top: 0px;
        right: 430px;
        
        &.comment {
            right: auto;
            left: 152px;
            top: -8px;
            margin: 0;
        }

        .subscriber-item {
            width: 28px;
            height: 28px;
            border-radius: 500px;
            background-size: cover;
            display: inline-block;
            margin-left: -15px;
        }

        button {
            display: inline;
            vertical-align: top;
            margin-left: 5px;
        }
    }
}