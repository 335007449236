.file-form-input-button {
    width: 100%;
    position: relative;
}

.file-form-input-button .file-create-at {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: smaller;
}