.knowledge-base-page-markdown-view {
    .image-container {
        text-align: center;
        img {
            max-width: 33% !important;
            max-height: 120px!important;
            cursor: zoom-in;
        }
    }

    table td .image-container {
        img {
            max-width: 100% !important;
        }
    }
}