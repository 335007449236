.report-elements-container {
    position: relative;
    margin: auto;
    background-color: white;
}

.editable .report-elements-container {
    border-left: 1px dashed #C4C4C4;
    border-right: 1px dashed #C4C4C4;
}

.report-element {
    position: absolute !important;
    text-align: center;
}

.report-element {
    border: 1px solid transparent;
    cursor: default;
    overflow: visible;
}

.editable .report-element {
    border: 1px solid #C4C4C4;
    cursor: pointer;
}
.report-element .placeholder-img{
    display: none;
}
.editable .report-element .placeholder-img{
    display: inline;
}

.report-element:hover {
    border: 1px solid #C4C4C4;
}

.apx-legend-position-right {
    text-align: left;
}

.add-page-button-container {
    padding: 2px 0 0px 5px;
}

.create-report-page-modal .ui.input {
    width: 100%;
}

.draggable-element {
    position: absolute;
    left: 0;
    top: 3px;
}

.draggable-element i {
    color: #C4C4C4;
    cursor: grab !important;
}

.draggable-element i.grabbing {
    cursor: grabbing !important;
}

img.left-right-auto {
    margin-left: auto;
    margin-top: auto;
    height: 100%;
}

img.top-bottom-auto {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}


.menu-element {
    position: absolute;
    right: 10px;
    top: 0;
}

.report-pages-container {
    position: relative;
}
.report-pages-container.hide {
    display: none;
}

.report-container.hide-cursor {
    cursor: none;
}

.report-container.full-screen .report-pages-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}

.report-container.with-left-menu {
    margin-left: 60px;
}

.full-screen-button {
    position: absolute;
    right: 5px;
    top: 9px;
    cursor: pointer;
}

.page-controller-left, .page-controller-right {
    position: absolute;
}
.page-controller-left {
    top: 0;
    left: 0;
}
.page-controller-right {
    top: 0;
    right: 0;
}