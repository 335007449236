.array-string-container {
    &>.dropdown{
        width: 100%;
        &>.visible.menu.transition {
            display: none !important;
        }
    }
}

.field.is-changed>.array-string-container {
    .ui.dropdown {
        border: 1px solid #a291fb !important;
    }

    &>.ui.search.dropdown>input {
        border: none !important;
    }
}