.gantt-diagram-component {
    display: flex;
    .column-header {
      width: 10%;
      .row-header{
        padding-top: 4px;
        text-align: left;
        padding-left: 5px;
        font-weight: 600;
      }
    }
  
    .column-data {
      width: 90%;
      position: relative;
      overflow-x: auto;
      .column-data-inner {
        .scale{
          display: flex;
        }
      }
      .row-header {
        position: absolute;
        left: 0px;
        top: 0px;
      }
      .lines-container {
        position: absolute;
        left: 50px;
        right: 0px;
        height: 30px;
        .line {
          background-color: #0d5aa7;
          border-radius: 5px;
          color: white;
          height: 25px;
          position: absolute;
          text-align: center;
          top: 2px;
        }
      }
    }
  
  
    .row {
      height: 30px;
      border-bottom: 1px solid #C4C4C4;
    }
}