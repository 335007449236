.field.is-changed>.dictionaries-input-container {
  .ui.dropdown {
    border: 1px solid #a291fb !important;
  }
  
  &>.ui.search.dropdown>input {
    border: none !important;
  }
}

.dictionaries-input-container {
  .dropdown {
    width: 100%;
  }
}