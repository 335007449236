.search-image-modal div.ui.input, .search-image-modal input {
    width: 100% !important;
    display: block !important;
}

.results {
}

.results>div {
    display: inline-block;
    margin: 15px;
}
.results img {
    max-width: 150px;
    cursor: pointer;
}

.image-select-input input {
    padding-right: 47px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.image-select-input.not-empty input{
    padding-left: 28px !important;
}