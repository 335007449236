.field.is-changed>.enums-input-container {
  .ui.dropdown {
    border: 1px solid #a291fb !important;
  }
  
  &>.ui.search.dropdown>input {
    border: none !important;
  }
}

.enums-input-container {
  .dropdown{
    width: 100%;
  }
}