.report-dictionary-sort-container {
    position: absolute;
    top: 3px;
    right: -8px;
}

.report-dictionary-sort-container-icon {
    margin: 0;
    cursor: pointer;
    color: #cccccc;
}

.report-dictionary-sort-container-icon:hover {
    margin: 0;
    cursor: pointer;
    color: #7d7e80;
}


.report-dictionary-sort-container-icon.active {
    color: #333333;
}

.report-dictionary-sort-container.last{
    right: 0 !important;
}