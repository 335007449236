.license-info-alert img {
    width: 340px;
}

.license-info-alert p {
    padding: 15px;
    padding-top: 0;
    width: 340px !important;
    font-size: 18px;
}
.license-info-alert {
    position: absolute;
    top: auto;
    left: 40px;
    bottom: 60px;
    background: #ffffff78;
    border-radius: 15px;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .license-info-alert {
        left: 0px;
        bottom: 0px;
        border-radius: 0px;
    }
    .license-info-alert img {
        width: 100px;
    }
    .license-info-alert p {
        font-size: 15px;
        padding: 5px;
        padding-top: 0;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {  }

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}
