.calendar-page-container {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.calendar-page-container .message{
    text-align: center;
}

.calendar-page-container .events-viewer-container {
    position: relative;    
}

.calendar-page-container .events-viewer-container .view-type-switcher-container {
    text-align: right;
    padding-right: 10px;
}
.calendar-page-container .events-viewer-container .view-type-switcher {
    display: inline-block;
}

.calendar-page-container .events-viewer-container .day-container {
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid black;
    width: 14.2%;
}
.calendar-page-container .events-viewer-container .day-container .day-header-name {
    text-align: center;
    font-weight: bold;
}

.calendar-page-container .events-viewer-container .day-container .day-header {
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid black;
}
.calendar-page-container .events-viewer-container .day-container .day-header .is-current {
    text-decoration: underline;
}
.calendar-page-container .events-viewer-container .day-container .hours-container {
    position: relative;
}
.calendar-page-container .events-viewer-container .day-container .hours-container .hour-container {
    border-bottom: 1px solid black;
    height: 40px;
    position: relative;
}
.calendar-page-container .events-viewer-container .day-container .hours-container .hour-container .hour-description {
    color: #7d8492;
    position: absolute;
    left: -40px;
    top: -11px;
}

.calendar-page-container .events-viewer-container .week-container .calendar-header-container {
    padding-left: 40px;
    width: calc(100% - 10px);
}
.calendar-page-container .events-viewer-container .week-container .calendar-days-container {
    overflow-y: auto;
    padding-left: 40px;
}

.is-current-time {
    position: absolute;
    border-bottom: 2px solid red;
    width: 100%;
}

.is-current-time .circle-for-current-time {
    border-radius: 20px;
    width: 10px;
    height: 10px;
    background-color: red;
    position: absolute;
    left: -5.5px;
    top: -4px;
}

.calendar-page-container .events-viewer-container .event-container {
    background-color: #7d8492;
    border-radius: 5px;
    color: white;
    padding: 0px 5px;
    border: 1px solid black;
    position: absolute;
    width: 90%;
    cursor: pointer;
}

.calendar-page-container .events-viewer-container .event-container .title {
    font-weight: bold;
}
.calendar-page-container .events-viewer-container .event-container .from-to-time {}
.calendar-page-container .events-viewer-container .event-container .members {
    position: absolute;
    bottom: 0;
    right: 18px;
}
.calendar-page-container .events-viewer-container .event-container .members .member-image {
    width: 28px;
    height: 28px;
    border-radius: 500px;
    background-size: cover;
    display: inline-block;
    margin-right: -13px;
}

.day-view-container {}

.calendar-page-container .events-viewer-container .day-view-container .day-container {
    width: 100%;
}

.month-container {
}

.month-container .day-in-month-container {
    display: inline-block;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    width: 14.2%;
    height: calc((100vh - 130px)/5);
}
.month-container .day-in-month-container.current-month {}
.month-container .day-in-month-container.is-monday {
    border-left: 1px solid black;
}
.month-container .day-in-month-container.is-first-week {
    border-top: 1px solid black;
}
.month-container .day-in-month-container .day-number {
    color: #7d8492;
    text-align: center;
}
.month-container .day-in-month-container.current-month .day-number {
    color: black;
}
.month-container .day-in-month-container .events {}