.email-client-login-page-container {
    background-image: url("https://thumb.tildacdn.com/tild3434-6264-4439-b766-333166343036/-/format/webp/Frame_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.email-client-login-page-container .login-border {
    width: 245px;
    margin: auto auto;
    background-color: rgb(95 175 255 / 55%);
    padding: 20px;
    border-radius: 10px;
}
.email-client-login-page-container .login-wrapper {
    width: 205px;
}

.email-client-login-page-container .login-wrapper button {
    width: 205px;
}