.report-editor-element-type-item {
    display: inline-block;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    height: 35px;
}

.report-editor-element-type-item:hover {
    border: 1px solid #767676;
    border-radius: 5px;
}

.report-editor-element-type-item img{
    width: 24px;
}

.report-editor-element-type-item.focused{
    border: 1px solid black;
    border-radius: 5px;
}

.report-editor-dictionary-viewer {
    border-radius: 10px;
    
}

.report-editor-dictionary-viewer.focused .fields-container{
    box-shadow: 0px 0px 68px currentColor;
    border-radius: 5px;
}

.report-editor-container .ui.list>.item>.icon+.content {
    padding: 0;
}


.toolbar-container {
    padding: 2px;
    border: 1px solid #c4c4c4;
    border-radius: 5px 5px 0 0;
    position: absolute;
    bottom: 0px;
    right: 63px;
}

.toolbar-container .dropdown {
    padding: 0 !important;
    margin: 2px;
    min-height: 27px !important;
    display: inline-block;
    line-height: unset !important;
}

.toolbar-container .dropdown .dropdown.icon {
    padding: 6px !important;
}

.toolbar_opener {
    padding: 0px;
    border: 1px solid #c4c4c4;
    border-radius: 0 0 5px 5px;
    position: absolute;
    bottom: -21px;
    right: 50px;
}