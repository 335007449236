.chat-page-container {
    &.with-left-menu {
        width: calc(100% - 60px);
        margin-left: 60px;
    }
}
#chat>.row {
    padding: 0;
    margin: 0;
    padding-top: 14px;
    margin-left: 14px;
}

.chat-list-container {
    padding: 0 !important;
}

.chat-list {
    overflow-y: scroll;
}

.chat-list-header {
    padding-left: 10px !important;
    padding-top: 10px !important;
    position: relative;
    height: 60px;
    background-color: #7d7e80;
}

.chat-list-header h1 {
    color: whitesmoke;
}
.chat-list-header button {
    position: absolute;
    top: 13px;
    right: 10px;
}

.chat-list-search {
    height: 50px;
    background-color: #929499;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
}
.chat-list-search > .search, .chat-list-search > .search > .input {
    width: 100% !important;
}

#chat-list .chat-item {
    height: 70px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    cursor: pointer;
    margin: 0 !important;
}

#chat-list .chat-item:hover {
    background-color: #bababa;
}

#chat-list .chat-item.active {
    background-color: #dcdcdc;
}

#chat-list .chat-item .row {
    padding: 0;
}
.chat-container {
    padding: 0px !important;
}

.chat-container .need-select-chat-message {
    text-align: center;
    padding-top: 30px;
}

.chat-list .chat-item .chat-item-img-container {
    width: 70px!important;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
}

.chat-item-img-container img {
    width: 50px !important;
}




.chat-item-body {
    padding-top: 15px;
    padding-left: 70px;
    padding-right: 70px;
}



.chat-item-title {
    font-size: large;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-item-text {
    color: #929499;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-item-time {
    position: absolute;
    right: 20px;
    bottom: 5px;
    color: #7d8492;
}



#chat-list .chat-item .chat-item-right-container{
    position: absolute;
    top: 20px;
    right: 20px;
}

.chat-header{
    height: 60px;
    background-color: #dedede;
    padding-left: 60px;
}

.chat-header .chat-header-img-container {
    width: 70px!important;
    padding: 4px;
    position: absolute;
    left: 0;
    top: 0;
}

.chat-header-img-container img {
    width: 50px !important;
}

.chat-header-body {
    padding-top: 10px;
}
.chat-header-title {
    font-size: large;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-header-text {
    color: #929499;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.chat-body{
    width: 99%;
    padding: 50px;
    padding-left: 100px;
    padding-right: 100px;
    overflow-y: scroll;
    background-color: #cfcfcf;
}

.chat-body .message {
    min-width: 10%;
    max-width: 70%;
    width: 30%;
}
.chat-body .message-container{
    position: relative;
}
.chat-body .message-container .ui.message {
    margin-bottom: 0px;
    margin-top: 0px;
}

.chat-body .message-container .time-description{
    position: absolute;
    right: 10px;
    bottom: 3px;
    color: #929499;
}

.chat-body .message-container.from-me{
}

.chat-body .message-container.from-me .message {
    position: absolute;
    right: 0;
}

.chat-body .message-container.from-me .message.fake {
    position: relative;
    opacity: 0;
}

.chat-body .message-container .threeangle {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -15px;
    top: 0px;
    border-top: 1px rgb(201 202 202) solid;
    overflow: hidden;
}
.chat-body .message-container.from-me .threeangle {
    right: -15px;
    left: auto;
}

.chat-body .message-container .threeangle-inner {
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background-color: rgb(248, 248, 249);
    border-bottom: 1px rgb(201 202 202) solid;
    top: -21px;
    left: 0px;
    position: absolute;
}

.chat-body .message-container.from-me .threeangle-inner {
    border-bottom: none;
    border-right: 1px rgb(201 202 202) solid;
}

.chat-body .message-container .file-attach-in-message {
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid gray;
    padding: 5px 10px;
}

.chat-message-input-block {
    position: relative;
    padding-top: 5px;
}

.chat-message-input-block form {
    padding-left: 90px;
    padding-right: 150px;
    margin-top: 10px;
}

.chat-message-input-block>form>i:nth-child(1) {
    left: 14px;
    top: 12px;
    position: absolute;
    cursor: pointer;
}

.chat-message-input-block .file-attach-btn-container {
    left: 50px;
    top: 12px;
    position: absolute;
    cursor: pointer;
}

.chat-message-input-block button {
    right: 20px;
    height: 41px;
    top: 0;
    position: absolute;
}

.chat-message-input-block .chat-message-input-block-attachments {}
.chat-message-input-block .chat-message-input-block-attachments .file-attach {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 3px 5px;
}


.emoji-picker-container {
    position: absolute;
    bottom: 70px;
}

.chat-action {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
}