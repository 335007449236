.cards-view {
    padding-top: 5px;
}

.cards-view .cards-column {
    position: relative;
    display: inline-block;
    width: 300px;
    border-radius: 5px;
    border: 1px solid rgb(222, 222, 223);
    background-color: rgba(222, 222, 223, .28);
    padding: 15px;
    margin-right: 5px;
    vertical-align: top;
}

.cards-view .cards-column  .cards-column-header {
    margin-bottom: 10px;
    cursor: default;
}

.cards-view .cards-column .cards-column-content {
    max-height: calc(100vh - 220px);
}

.cards-view .cards-column .cards-column-content.with-scroll {
    overflow-y: auto;
}

.cards-view .cards-column .cards-column-content .card-content {
    position: relative;
    border: 1px solid rgb(222, 222, 223);
    background-color: white;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px;
}

.cards-view .cards-column .collapse-column-container {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.cards-view .cards-column.collapsed {
    writing-mode: vertical-rl;
    width: 37px;
    padding: 30px 5px 15px 5px;
}

.cards-view .cards-column.collapsed .collapse-column-container {
    right: 4px;
}


.cards-view .cards-column .cards-column-content .card-content.with-action-invoker, .cards-view .cards-column .cards-column-content .card-content.with-selectable-checkbox {
    padding-bottom: 25px;
}

.cards-view .cards-column .cards-column-content .card-content.with-id-value, .cards-view .cards-column .cards-column-content .card-content.with-create-at {
    padding-top: 25px;
}

.cards-view .cards-column .cards-column-content .card-content .action-invoker-container {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.cards-view .cards-column .cards-column-content .card-content .selectable-checkbox-container {
    position: absolute;
    bottom: 0px;
    left: 10px;
}

.cards-view .cards-column .cards-column-content .card-content .id-value-container {
    position: absolute;
    top: 3px;
    left: 10px;
}

.cards-view .cards-column .cards-column-content .card-content .create-at-container {
    position: absolute;
    top: 3px;
    right: 10px;
}