.public-form-container {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}

.public-form-container .page-name {
    text-align: center;
    padding-top: 70px;
}

.public-form-container .logo-container {
    position: absolute;
    left: 124px;
    top: 21px;
    width: 250px;
}

.public-form-container .logo-container img {
    width: 100%;
}

.public-form-container {
    .infoblock-container, .form-container, .send-message {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }
}

.public-form-container.dictionary-modal-size-fullscreen {
    //width: calc(100% - 100px);
    .infoblock-container, .form-container, .send-message {
        max-width: calc(100% - 100px); 
    }
}
.public-form-container.dictionary-modal-size-large {
    //width: 1140px;
    .infoblock-container, .form-container, .send-message {
        max-width: 1140px;
    }
}
.public-form-container.dictionary-modal-size-small {
    //width: 760px;
    .infoblock-container, .form-container, .send-message {
        max-width: 760px;
    }
}
.public-form-container.dictionary-modal-size-tiny {
    //width: 570px;
    .infoblock-container, .form-container, .send-message {
        max-width: 570px;
    }
}
.public-form-container.dictionary-modal-size-mini {
    //width: 345px;
    .infoblock-container, .form-container, .send-message {
        max-width: 345px;
    }
}


/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .public-form-container {
        padding: 15px;
        .infoblock-container, .form-container, .send-message {
            margin-top: 15px;
        }
        button {
            width: 100%;
        }
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {  }

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}
