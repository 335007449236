.login-by-token-page .login-by-token-page-loader-wrapper {
    min-height: 100px  !important;
    width: 100px  !important;
    height: 100px  !important;
    display: block !important;
    position: absolute !important;
    top: 0 !important;;
    bottom: 0 !important;;
    left: 0 !important;;
    right: 0 !important;;
    margin: auto !important;;
}

.login-by-token-page p {
    min-height: 100px  !important;
    width: 400px  !important;
    height: 100px  !important;
    display: block !important;
    position: absolute !important;
    top: 0 !important;;
    bottom: 0 !important;;
    left: 0 !important;;
    right: 0 !important;;
    margin: auto !important;;
    text-align: center;
}