.report-expression-suggestions-container {
    position: absolute;
    z-index: 999;
    margin-top: 5px;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    border: solid rgb(212, 212, 213) 1px;
}

.report-expression-suggestions-container .label{
    cursor: pointer;
}