.python-tasks-instruction-page {
  padding-top: 50px; 
  padding-bottom: 100px;

  h1 {
    margin-bottom: 70px !important;
  }


  p {
    margin-top: 50px;
    margin-bottom: 50px;
    ul li a {
      cursor: pointer;
    }
    
    .dictionary-name {
      .copy-icon {
        opacity: 0;
        cursor: pointer;
      }
    }
    .dictionary-name:hover {
      .copy-icon {
        opacity: 1;
      }
    }
  }
  .block {
    margin-top: 250px !important;  
  }
  
  h3 {
    margin-top: 75px !important;
  }
}