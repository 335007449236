.knowledge-base-folder-content-items {
    .item {
        height: 40px;
        border-top: 1px solid rgba(34, 36, 38, .15);
        padding-top: 10px;
        padding-left: 15px;
        cursor: pointer;
        
        &:first-child {
            border-top: none;
        }
        
        &:hover {
            background-color: rgba(0,0,0,.05);
        }
    }
}