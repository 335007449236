.settings-menu-item{
    &.active{
        border-bottom: 2px black solid !important;
    }
    .dropdown.link {
        &>.dropdown.icon {
            display: none;
        }
    }
    .public-pages-menu-item {
        .text {
            padding-left: 25px;
        }
    }
}