.folder-viewer-container-for-select {
    border-radius: .28571429rem;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15), 0 0 0 1px rgba(34, 36, 38, .15);
    >.item {
        margin: 0;
        padding: .75em 1em;
        color: rgba(0, 0, 0, .4);
        font-weight: 700;
        border-top: 1px solid rgba(34, 36, 38, .15);
        -webkit-transition: background .1s ease, color .1s ease;
        transition: background .1s ease, color .1s ease;
        cursor: pointer;
        position: relative;
    }

    >.inner-folders {
        padding-left: 25px;
    }
}

.inner-folders>.folder-viewer-container-for-select {
    box-shadow: none;
}

.inner-folders>.folder-viewer-container-for-select>.item {
    border-top: none;
}

.folder-viewer-container-for-select>.item:hover, .folder-viewer-container-for-select>.item.current {
    background: 0 0;
    color: rgba(0, 0, 0, .87);
}

.folder-viewer-container-for-select>.item>.select {
    opacity: 0;
    position: absolute;
    right: 25px;
}

.folder-viewer-container-for-select>.item:hover>.select {
    opacity: 1;
}