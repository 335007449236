.knowledge-base-history-modal {
    .menu-history .history-item {
        border-top: 1px solid rgba(34, 36, 38, .15);
        height: 70px;
        position: relative;
        cursor: pointer;
        
        &:first-child {
            border-top: none;
        }

        .avatar {
            position: absolute;
            width: 28px;
            height: 28px;
            background-size: cover;
            border-radius: 500px;
            left: 10px;
            top: 10px;
        }
        .name {
            position: absolute;
            left: 46px;
            top: 13px;
            font-weight: 600;
        }

        &.active {
            border-left: 5px solid #0d71bb;
            .avatar {
                left: 5px;
            }
            .name {
                left: 41px
            }
        }

        .update-at {
            position: absolute;
            right: 10px;
            bottom: 10px;
            opacity: 0.5;
        }
    }
}