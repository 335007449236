.image-input-container {
    .preview-container {
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 5px;
        
        .image {
            display: inline-block;
            width: 100%;
            aspect-ratio : 1 / 1;
            /*background-position: center;*/
            background-size: contain;
            background-repeat: no-repeat;
            max-width: 140px;
        }
    }
}

.field.is-changed>.image-input-container>.preview-container {
    border: 1px solid #a291fb !important;
}