.color-picker-container {
    position: relative;
}

.color-picker-value-input {
    border: solid 1px rgba(34, 36, 38, 0.15);
    border-radius: 5px;
    height: 38px;
    cursor: pointer;
    overflow: hidden;
}

.color-picker-value-input-actions-container {
    position: absolute;
    right: 10px;
    top: 9px;
    background-color: white;
    border-radius: 5px;
    color: #767676;
}

.color-picker-popover {
    position: absolute;
    z-index: 2;
}

.color-picker-cover {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;   
}

.color-picker-action-block {
    width: 223px;
    text-align: right;
    padding-top: 10px;
}