.report-dictionary-container {
    margin-top: 5px;
}

.report-dictionary-page.with-left-menu {
    margin-left: 60px;
    width: calc(100% - 60px);
    height: 100vh;
}

.report-dictionary-page.with-left-menu .grid-container {
    height: calc(100vh - 89px);
}

.report-dictionary-page.with-left-menu .grid-container.with-extra-filters {
    height: calc(100vh - 125px);
}

.report-dictionary-container-empty {
    min-height: 382px;
    height: 382px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    
    width: 30% !important;
    max-width: 30% !important;
    min-width: 30% !important;
    
}

.report-dictionary-container-empty img.description-img {
    width: 100%;
}

.report-dictionary-container .report-dictionary-header .search .input {
    width: 100%;
}

.report-dictionary-container .report-dictionary-header .create-btn-container {
    padding-top: 2px;
    text-align: right;
}
 .report-dictionary-header .dictionary-list-header-btn-container {
    padding-top: 2px;
    text-align: right;
}

.report-dictionary-page {
    padding-top: 10px;
    height: calc(100% - 40px);
    background-position: center center;
    background-size: cover;
}

.report-dictionary-page .blur {
    height: calc(100% + 9px);
}

.report-dictionary-header {
    padding-top: 10px !important;
}

.dictionary-search-count {
    background-color: white;
    border-radius: 5px;
    white-space: nowrap;
}

.report-dictionary-full-screen {
    padding: 0 17px;
}

.report-dictionary-page .ui.search .input {
    width: 100%;
}


.report-dictionary-container table td .avatar {
    width: 40px;
    height: 40px;
    background-size: cover;
    border-radius: 500px;
}

.report-dictionary-container table td .avatar.default {
    border-radius: 0px;
}

.report-dictionary-container table td>.image {
    width: 50px;
    height: 40px;
    background-size: cover;
}


.report-dictionary-container table td>.images>.image, .report-dictionary-container table td>.images>div>.image {
    width: 50px;
    height: 40px;
    background-size: cover;
}
.backgorund-for-row-red, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-red {
    background-color: #db2828;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-red:hover {
    background-color: #de3939!important;
    color: #fff!important;
}

.backgorund-for-row-orange, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-orange {
    background-color: #f2711c;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-orange:hover {
    background-color: #f37c2e!important;
    color: #fff!important;
}

.backgorund-for-row-yellow, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-yellow {
    background-color: #fbbd08;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-yellow:hover {
    background-color: #fbc21b!important;
    color: #fff!important;
}

.backgorund-for-row-olive, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-olive {
    background-color: #b5cc18;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-olive:hover {
    background-color: #bbd02a!important;
    color: #fff!important;
}

.backgorund-for-row-green, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-green {
    background-color: #21ba45;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-green:hover {
    background-color: #32bf54!important;
    color: #fff!important;
}

.backgorund-for-row-teal, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-teal {
    background-color: #00b5ad;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-teal:hover {
    background-color: #14bbb3!important;
    color: #fff!important;
}

.backgorund-for-row-blue, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-blue {
    background-color: #2185d0;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-blue:hover {
    background-color: #328fd4!important;
    color: #fff!important;
}

.backgorund-for-row-violet, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-violet {
    background-color: #6435c9;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-violet:hover {
    background-color: #7045cd!important;
    color: #fff!important;
}

.backgorund-for-row-purple, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-purple {
    background-color: #a333c8;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-purple:hover {
    background-color: #aa43cc!important;
    color: #fff!important;
}

.backgorund-for-row-pink, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-pink {
    background-color: #e03997;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-pink:hover {
    background-color: #e2499f!important;
    color: #fff!important;
}

.backgorund-for-row-brown, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-brown {
    background-color: #a5673f;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-brown:hover {
    background-color: #ac734e!important;
    color: #fff!important;
}

.backgorund-for-row-grey, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-grey {
    background-color: #767676;
    color: #fff!important;
}

body .ui.selectable.table tbody tr.backgorund-for-row-grey:hover {
    background-color: #818181!important;
    color: #fff!important;
}

.backgorund-for-row-black, .ui.striped.table tbody tr:nth-child(2n).backgorund-for-row-black {
    background-color: #1b1c1d;
    color: #fff!important;
}
body .ui.selectable.table tbody tr.backgorund-for-row-black:hover {
    background-color: #2d2e2f!important;
    color: #fff!important;
}

.grid-container.on-scrolling {
    border-top: 1px solid #dededf;
    border-radius: 5px;
}

.dictionary-empty-after-search-message-container {
    text-align: center;
    padding-top: 30px;
}
.dictionary-empty-after-search-message-container p {
    font-size: larger;
    font-weight: bold;
}


.sum-btn {
    position: absolute;
    bottom: 0px;
    right: 20px;
    color: white;
    font-weight: bold;
    background-color: #7d8492;
    border-radius: 5px 5px 0px 0px;
    padding: 5px;
    cursor: pointer;
}

.sum-info-container {
    position: absolute;
    bottom: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.sum-info-container>.container{
    padding-left: 15px;
    padding-right: 15px;
}

.report-dictionary-page .avatar-in-list-column {
    background-size: cover;
    width: 28px;
    height: 28px;
    display: inline-block;
    border-radius: 500px;
    vertical-align: middle;
    margin-top: -4px;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .report-dictionary-header {
        padding-top: 0px !important;
        margin-left: 40px!important;
        height: 65px;
    }

    .report-dictionary-header .row {
        padding: 0px!important;
    }

    .report-dictionary-header.ui.grid>.row>.create-btn-container[class*="five wide"].column {
        width: 37px !important;
        position: absolute !important;
        padding: 0px;
        top: 10px;
        right: 10px;
    }
    .report-dictionary-header .create-btn-container button {
        padding: 11px !important;
    }

    .report-dictionary-header.ui.grid>.row>.dictionary-list-header-btn-container[class*="five wide"].column {
        width: 100% !important;
        padding: 0px;
        position: absolute !important;
        top: 0px;
    }

    .report-dictionary-header.ui.grid>.row>.dictionary-list-header-btn-container[class*="five wide"].column>div.button {
        position: absolute !important;
        top: 10px;
        right: 50px;
    }

    .report-dictionary-header.ui.grid>.row>.dictionary-list-header-btn-container[class*="five wide"].column>button {
        position: absolute !important;
        top: 10px;
        right: 96px;
    }

    .report-dictionary-header.ui.grid>.row>.dictionary-search-count[class*="two wide"].column {
        width: 100% !important;
        padding: 0px;
        padding-top: 17px!important;
        position: absolute !important;
        top: 0px;
    }
    
    .dictionary-list-header-btn-container .menu {
        width: 200px;
    }
    .dictionary-list-header-btn-container .menu div[role="option"]{
        white-space: normal!important;
        line-height: 25px!important;
    }

    .report-dictionary-page .report-dictionary-container-empty .grid .row {
        display: block !important;
        padding-top: 0px;
    }
    
    .report-dictionary-page .report-dictionary-container-empty .grid .row .column {
        display: block !important;
        width: 100% !important;
    }

    .report-dictionary-page .report-dictionary-container-empty .grid .row .column .description-img {
        width: 50%;
        margin-left: 25%;
    }
    
    .dictionary-name-for-empty {
        position: absolute;
        top: 17px;
        left: 50px;
        font-size: 20px;
        font-weight: bold;
    }

    .report-dictionary-page .report-dictionary-container-empty .message {
        padding: 25px 10px;
    }

    .report-dictionary-page .report-dictionary-container-empty .message .button {
        width: 100%;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .report-dictionary-container-empty {
        width: 60% !important;
        max-width: 60% !important;
        min-width: 60% !important;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}