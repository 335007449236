.folder-viewer-container .change-permissions-icon {
    position: absolute;
    right: 27px;
    top: 10px;
    opacity: 0.2;

    &.min-padding {
        right: 25px;
    }

    &.middle-padding {
        right: 40px;
    }

    &.max-padding {
        right: 60px;
    }
    
    &:hover {
        opacity: 1;
    }
}