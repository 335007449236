.left-menu {
  position: fixed;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 50px;
  border-radius: 5px;
  background-color: rgba(128, 128, 128, 0.32);
  .menu-item {
    position: relative;
    cursor: pointer;
    padding: 12px;
    text-align: center !important;
    &.logo {
      background-color: #0d5aa7;
      border-radius: 50px;
      color: white;
      font-weight: bold;
      font-size: 24px;
      width: 40px;
      height: 40px;
      margin-left: 5px;
      margin-top: 5px;
      padding: 9px 12px;
      cursor: default;
      &.image {
        background-color: transparent;
        border-radius: 0px;
        padding: 3px;
        img {
          width: 100%;
        }
      }
    }
    &.active {
      border-left: 3px solid black;
      padding-left: 9px;
    }
    &.profile {
      padding: 0px;
      >.dropdown {
        padding: 16px;
      }
      >.dropdown.avatar-container {
        padding-left: 7px !important;
        padding-right: 7px !important;
        padding-top: 0px !important;
        padding-bottom: 3px !important;
      }
    }
    
    &.global-filter {
      padding: 0px;
      .global-filter-action-menu-item {
        padding-left: 0px !important;
      }
      .global-filter-menu-item {
        min-width: auto;
        .dropdown {
          margin-top: 15px;
          font-weight: bold;
          width: 100% !important;
          text-align: center;
          &>.dropdown.icon {
            display: none;
          }
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0px;
  }
}