.search-by-knowledge-base-model {
    .ui.input{
        width: 100%;
        display: block;
        input {
            width: 100%;
            border-radius: 50px;
        }
    }
    .container.text {
        max-width: 100% !important;
    }
}