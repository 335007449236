.folder-viewer-container {
    .folder-changer-icon {
        position: absolute;
        right: 20px;
        top: 11px;
        opacity: 0.2;
        
        &.min-padding {
            right: 5px;
        }
        
        &.max-padding {
            right: 40px;
        }

        &:hover {
            opacity: 1;
        }
    }
}