.images-input-container {
    .preview-container {
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 15px;
        overflow-x: auto;
        
        .image {
            display: inline-block;
            width: 100%;
            aspect-ratio : 1 / 1;
            background-size: contain;
            background-repeat: no-repeat;
            max-width: 140px;
            margin: 10px;
            position: relative;
            .delete-btn {
                position: absolute;
                right: 0;
                top: 3px;
                padding: 3px
            }
        }
    }
}

.field.is-changed>.images-input-container>.preview-container {
    border: 1px solid #a291fb !important;
}