.code-example {
  position: relative;
  .copy-icon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 999;
  }
}