.column-filter-form .checkbox-can-empty-for-date-time {
    position: absolute;
    top: 0px;
    right: 0px;
}

.column-filter-form .date-switcher-container {
    position: absolute;
    top: 32.5px;
    right: -11px;
    cursor: pointer;
    z-index: 9999999;
}