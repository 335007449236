.avatar-input-container .preview-container {
    text-align: center;
}

.avatar-input-container .preview-container .image {
    display: inline-block;
    width: 100%;
    aspect-ratio : 1 / 1;
    /*background-position: center;*/
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 240px;
    border-radius: 500px;
}