.main-company-logo {
  padding: 0 !important;
}

.main-company-logo img {
  width: auto!important;
  max-width: 140px!important;
  max-height: 38px;
  height: auto;
  margin: 11px 8px !important;
}


.global-filter-addition-btn {
  position: absolute;
  top: 15px;
  right: -11px;
  padding: 0px !important;
  width: 25px;
  height: 25px;
}


.right.menu [role="listbox"] .menu.transition.visible{
  margin-top: 15px;
}

.global-filter-action-menu-item{
  padding-right: 0 !important;
  padding-left: 11px !important;
}

.global-filter-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 110px;
}

.global-filter-menu-item .text[role="alert"]{
  width: calc(100% - 20px) !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.global-filter-menu-item [role="listbox"]{
  width: calc(100% - 20px) !important;
}

header .project-name-container{
  padding-top: 3px;
  padding-left: 8px;
  border-right: 2px solid rgba(34, 36, 38, 0.15);
  padding-right: 9px;  
  cursor: default;
}

.header.item.main-company-logo{
  max-height: 40px;
}

.help-menu-link{
  color: black;
  padding-top: 8px;
}

header .avatar-container {
  padding-top: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 0 !important;
  align-self: center !important;
}

header .avatar-container .avatar-image {
  width: 35px;
  height: 35px;
  background-size: cover;
  border-radius: 500px;
}

header .avatar-container .avatar-image.default {

}

header .background-tasks-menu-item .indicator-container {
  right: 1px !important;
}

.background-tasks-information-list .create-at{
  color: #6b6b6b;
  font-size: small;
}


/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
  header .for-mobile>.open-menu-btn {
    position: absolute;
    top: 15px;
    left: 10px;
  }
  header .for-mobile>.placeholder-background {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  }
  header .for-mobile>.menu {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 250px;
    z-index: 999999;
    background-color: white;
  }
  header .for-mobile>.menu>.menu {
    width: auto;
  }
  header .for-mobile>.menu>.menu>.item {
    position: relative;
    padding-left: 45px !important;
    font-size: 18px;
  }
  header .for-mobile>.menu>.menu>.item i {
    position: absolute;
    left: 5px;
    top: 16px;
  }

  header .for-mobile>.menu>.menu>.item.main-company-logo {
    width: 100%;
    height: 60px;
    max-height: 60px;
    display: block;
    position: relative;
    text-align: left;
    padding-top: 15px !important;
    padding-left: 12px !important;
    padding-right: 0px !important;
    margin-bottom: 25px;
  }
  header .for-mobile>.menu>.menu>.item.main-company-logo img {
    display: inline-block !important;
  }

  header .for-mobile .help-menu-link {
    position: absolute;
    top: 23px;
    right: 12px;
  }

  header .for-mobile .open-user-menu-btn {
    position: absolute !important;
    top: 12px;
    right: 12px;
  }

  header .for-mobile .open-user-menu-btn .menu {
    font-size: 16px;
    margin-top: 45px !important;
  }

  .global-filter-menu-item .dropdown.icon {
    display: none;
  }
  .global-filter-menu-item>[role="listbox"] {
    width: 180px !important;
    font-size: 18px;
    padding-left: 15px;
  }
  .global-filter-menu-item  .global-filter-action-menu-item[role="listbox"]{
    width: 40px !important;
    position: absolute !important;
    right: 29px;
    top: -15px;
    font-size: 16px;
  }

  header .for-mobile .global-filter-menu-item .menu {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  header .for-mobile .global-filter-menu-item .menu .item {
    width: 180px !important;
    white-space: initial !important;
    line-height: 20px;
  }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {  }

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}