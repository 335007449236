.SetNewPassword-page-container {
    padding-top: 100px;
}

.SetNewPassword-page-container .close-btn {
    width: 100px;
    opacity: 70%;
    position: absolute;
    top: 28px;
    right: 100px;
    cursor: pointer;
}

.SetNewPassword-page-container .close-btn .icon {
    padding-left: 17px;
}