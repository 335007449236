.dictionary-item-edit-modal {
  .header {
    position: relative;
    .comments-btn {
      display: block;
      position: absolute;
      bottom: 0px;
      right: 15px;
      cursor: pointer;
      font-size: small;
      font-weight: normal;
      &.is-hide {
        bottom: -25px;
      }
    }
  }
  .comments-container {
    width: 50%;
    padding: 15px;
  }
}