.dictionary-item {
    cursor: default;
}

.dictionary-item>button {
    position: absolute;
    width: 30px;
}

.dictionary-item span {
    cursor: pointer;
}

.dictionary-item.isFilter{
    background-color: #e0e1e2;
    color: rgba(0,0,0,.6) !important;
}

.ui.list .list>.item .header {
    padding-left: 16px;
    position: relative;
    cursor: default;
}

.focused .ui.list .list>.item .header {
    cursor: pointer;
}

.focused .ui.list .list>.item .header:hover {
    background-color: #e0e1e2;
}

.ui.list .list>.item .header span {

    width: 16px !important;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 1px;
    left: -3px;
}

.hovered { 
    background-color: rgba(0,0,0,.6);
    color: rgb(224, 225, 226);
}