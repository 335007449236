.dimmer-edit-python-code-fullscreen-modal {
  padding: 0 !important;
}

.edit-python-code-fullscreen-modal.ui.fullscreen.modal {
  margin: 0 !important;
  width: 100% !important;
  >.header {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  >.content{
    padding: 0px;
    .actions {
      position: absolute;
      right: 0px;
      bottom: 0px;
      background-color: white;
      padding: 10px 15px;
      border-radius: 10px 0px 0px 0px;
    }
  }
}