
.language-switcher {
    position: absolute;
    background-color: #ffffff;
    right: 124px;
    width: auto;
    top: 33px;
    padding: 5px;
    padding-left: 10px;
    border-radius: 5px;
}

.language-switcher .dropdown .divider.text i.flag {
    display: none;
}