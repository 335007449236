.knowledge-news-item-view {
    padding-left: 40px;
    position: relative;
    .avatar {
        width: 28px;
        height: 28px;
        background-size: cover;
        border-radius: 500px;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .content {}
}