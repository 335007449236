.login-form-wrapper {
    padding-top: 0 !important;
    width: 525px !important;
}

.login-form-wrapper .error {
    color: red;
}

.login-form-description {
    width: 288px !important;
    border-right: 1px solid #e0e1e2;
    padding-left: 33px !important;
    padding-top: 59px !important;
}

.login-form-description img {
    position: absolute;
    left: 25px;
    top: -7px;
    max-width: 240px;
    max-height: 67px;
}

.login-form-description h2 {
    position: absolute;
    top: 0;
}

.login-form-input-wrapper {
    width: 232px !important;
}

.login-form-forgot-pass {
    cursor: pointer;
    font-size: 10.3pt;
    padding-top: 9px;
    padding-bottom: 9px;
    display: inline-block;
}

.language-switcher {
    position: absolute;
    right: 82px;
    width: 224px;
    top: 33px;
}

#root, .login-page-wrapper {
    height: 100%;
}
.login-page-wrapper {
    background-position: center center;
    background-size: cover;
}


.login-page-wrapper .login-page-position-center
{
    background-color: #ffffff;
    border-radius: 5px;
    padding-top: 24px;
    padding-left: 8px;
    min-height: 185px;

    width: 522px;
    height: fit-content;

    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
    animation: fadeIn 1s;

    padding-bottom: 24px;
}

.login-page-wrapper .login-page-position-right
{
    background-color: #ffffff;
    padding-top: 144px;
    padding-left: 64px;
    min-height: 185px;
    width: 321px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
}

.login-page-wrapper .login-page-position-right .login-form-wrapper .row
{
    display: block;
}

.login-page-wrapper .login-page-position-right .login-form-wrapper .row .login-form-description
{
    display: block;
    border: none;
    margin-bottom: 88px;
    padding-left: 13px !important;
    width: 230px !important;
}

.login-page-wrapper .login-page-position-right .login-form-wrapper .row .login-form-description img {
    position: absolute;
    left: 12px;
    top: -11px;
    max-width: 203px;
    max-height: 70px;
}

.login-page-wrapper .login-page-position-right .login-form-wrapper .row .login-form-input-wrapper
{
    display: block;
}

.qr-code-link {
    position: absolute;
    right: 100px;
    bottom: 50px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}

.swagger-link-container {
    position: absolute;
    left: 100px;
    bottom: 50px;
}

.swagger-link-container img {
    width: 50px;
}

.login-form-wrapper.empty-description .login-form-description, .empty-description .login-form-input-wrapper, .empty-description .login-form-wrapper .row {
    display: block !important;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.empty-description .login-form-description {
    border-right: none;
}

.login-page-wrapper.empty-description .login-page-position-center {
    width: 300px;
    height: min-content;
    padding-bottom: 15px;
}
.empty-description .login-form-input-wrapper {
    padding-left: 4rem !important;
    padding-top: 15px !important;
}
.empty-description .login-form-input-wrapper {
    width: 277px !important;
}

.empty-description .login-form-description h2 {
    width: 100%;
    text-align: center;
    padding-right: 30px;
}

.empty-description .login-form-description img {
    width: 92%;
    max-width: none;
    max-height: 71px;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .login-form-description, .login-form-input-wrapper, .login-form-wrapper .row {
        display: block !important;
        -webkit-box-sizing: border-box !important;
        box-sizing: border-box !important;
    }

    .login-form-description {
        border-right: none;
    }

    .login-page-wrapper .login-page-position-center, .login-form-wrapper {
        width: auto !important;
    }

    .login-page-wrapper .login-page-position-center {
        min-height: 351px;
    }

    .login-form-wrapper.ui.grid {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .login-form-description {
        margin-bottom: 25px !important;
    }
    
    .login-form-wrapper .column.login-form-description {
        width: 100% !important;
        padding-left: 33px !important;
        padding-right: 33px !important;
    }
    
    .login-form-wrapper .column.login-form-input-wrapper {
        width: 100% !important;
        padding-left: 33px !important;
        padding-right: 33px !important;
    }

    .login-form-wrapper .column.login-form-input-wrapper .input {
        width: 100% !important;
    }

    .login-page-wrapper .login-page-position-center {
        border-radius: 0px;
    }

    .language-switcher {
        left: calc(65% - 145px);
        right: calc(65% - 145px) !important;
        top: -3px !important;
        text-align: center;
    }

    .swagger-link-container {
        display: none;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {  }

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}
